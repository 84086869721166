class MobileMenu {
    constructor(selector) {
        this.selector = selector;
        this.menu_elements = this.selector.querySelectorAll('.menu-item-has-children');
        if (this.menu_elements.length > 0) {
            this.menu_elements.forEach(element => {
                new MobileMenuElement(element);
            });
        }
    }
}

class MobileMenuElement {
    constructor(selector) {
        this.selector = selector;

        this._clickElement = this._clickElement.bind(this);

        let toggle = this.selector.querySelector(':scope > a');
        toggle.addEventListener('click', this._clickElement);

    }

    _clickElement(event) {
        event.preventDefault();
        if (this.selector.classList.contains('open')) {
            this.selector.classList.remove('open');
        } else {
            this.selector.classList.add('open');
        }
    }

}

export default MobileMenu;