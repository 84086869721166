import MobileMenu from "../mobileMenu";

let hamburger = document.querySelector('.js-hamburger');
let menu = document.querySelector('#mobile-menu');

const toggleMenu = (e) => {
    e.preventDefault();
    hamburger.classList.toggle('active');
    menu.classList.toggle('toggled');
    if (menu.classList.contains('toggled')) {
        document.body.style.overflowY = 'hidden';
        document.querySelector('html').style.overflowY = 'hidden';
        menu.focus();
        menu.setAttribute('aria-hidden', false);
    } else {
        document.body.style.overflowY = 'auto';
        document.querySelector('html').style.overflowY = 'auto';
        menu.setAttribute('aria-hidden', true);
    }
}

hamburger.addEventListener('click', toggleMenu);

let mobileMenu = menu.querySelector('.js-mobile-menu');
if (mobileMenu !== null) {
    new MobileMenu(mobileMenu);
}